<template>
  <b-row>
    <b-col cols="12">
      <div>
        <form-wizard
          :subtitle="null"
          :title="null"
          back-button-text="Previous"
          class="mb-3"
          color="#7367F0"
          finish-button-text="Submit"
          shape="square"
          @on-complete="$bvModal.show('eventModal')"
        >

          <!-- Basic Info tab -->
          <tab-content
            :before-change="validationBasicInfo"
            title="Basic Information"
          >
            <validation-observer
              ref="basicInfo"
              tag="form"
            >

              <b-row>
                <b-col
                  class="mb-2"
                  cols="12"
                >
                  <h5 class="mb-0">
                    Basic Information
                  </h5>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Program Type"
                    rules="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                      label="Program Type"
                      label-for="programType"
                    >
                      <v-select
                        id="programType"
                        v-model="program.type"
                        :options="['Pre-incubation Program', 'Incubation Program', 'Accelerator Program']"
                        label="Program Type"
                        placeholder="Program Type"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>

                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Program Name"
                    label-for="programTitle"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Program Name"
                      rules="required"
                    >
                      <b-form-input
                        id="programTitle"
                        v-model="program.title"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Program Name"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Startup Capacity"
                    label-for="programCapacity"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Program Capacity"
                      rules="required"
                    >
                      <b-form-input
                        id="programCapacity"
                        v-model="program.capacity"

                        :state="errors.length > 0 ? false:null"
                        placeholder="Number of participants"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Program Description"
                    label-for="programDescription"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Program Description"
                      rules="required"
                    >
                      <b-form-input
                        id="programDescription"
                        v-model="program.description"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Program Description"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Ideal Startup Lifecycle Stage"
                    rules="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                      label="Ideal Startup Lifecycle Stage"
                      label-for="lifeCycleStage"
                    >
                      <v-select
                        id="lifeCycleStage"
                        v-model="program.lifeCycleStage"
                        :options="['Ideation', 'Business Modelling', 'Prototyping', 'MVP', 'Market Traction', 'Product Introduction', 'Revenue Generation', 'Scaling']"
                        append-to-body
                        multiple
                        placeholder="Select one or multiple options"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>

                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Participant Industry/Sector"
                    rules="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                      label="Participant Industry/Sector"
                      label-for="industry"
                    >

                      <v-select
                        id="industry"
                        v-model="program.industry"
                        :options="industries"
                        append-to-body
                        multiple
                        placeholder="Select one or multiple options"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="6"
                >
                  <!-- Styled -->
                  <b-form-group
                    label="Upload Program Poster(Image)"
                    label-for="programPoster"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="File"
                      rules="size:2000"
                    >
                      <b-form-file
                        v-model="program.file"
                        accept="image/jpeg, image/png, image/gif"
                        style="margin-bottom: 0.5rem;"
                        :state="errors.length > 0 ? false:null"
                        drop-placeholder="Drop file here..."
                        placeholder="Choose a file or drop it here..."
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <DurationInput v-model="program.duration" />
                </b-col>
                <b-col>
                  <hr>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label="Fundraising Assistance"
                  >
                    <div class="d-flex">
                      <b-form-checkbox-group
                        v-model="program.fundraising"
                        :options="[{ text: 'Demo Day', value: 'demoDay', disabled: program.fundraisingDisable},
                                   { text: 'Personal Pitching', value: 'personalPitching', disabled: program.fundraisingDisable },
                                   { text: 'Liasoning', value: 'Liasoning', disabled: program.fundraisingDisable }
                        ]"
                        class="d-inline"
                        disabled-field="disabled"
                      />
                      <b-form-checkbox
                        v-model="program.inHouseFunds"
                        class="custom-control-primary mr-1"
                        value="true"
                        :disabled="program.fundraisingDisable"
                      >In-House Funds
                      </b-form-checkbox>
                      <b-form-checkbox
                        v-model="program.fundraisingDisable"
                        class="custom-control-danger"
                        value="true"
                        @change="program.fundraisingDisable ? () => {program.fundraising = []; program.inHouseFunds = null}: null "
                      >
                        None
                      </b-form-checkbox>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="!program.fundraisingDisable && program.fundraising.length"
                  sm="12"
                  md
                >
                  <b-form-group
                    label="Ticket Size Lower limit"
                    label-for="fundRaisingSize_l"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Lower Limit"
                      rules="required"
                    >
                      <b-form-input
                        id="fundRaisingSize_l"
                        v-model="program.fundRaisingSize[0]"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Lower Limit"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="!program.fundraisingDisable && program.fundraising.length"
                  sm="12"
                  md
                >
                  <b-form-group
                    label="Ticket Size Upper limit"
                    label-for="fundRaisingSize_u"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Upper Limit"
                      rules="required"
                    >
                      <b-form-input
                        id="fundRaisingSize_u"
                        v-model="program.fundRaisingSize[1]"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Upper Limit"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="!program.fundraisingDisable && program.inHouseFunds"
                  sm="12"
                  md
                >
                  <b-form-group
                    label="In-house Funds"
                    label-for="inHouseFunds"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Inhouse Funds"
                      rules="required"
                    >
                      <b-form-input
                        id="inHouseFunds"
                        v-model="program.inHouseFundsSize"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Total Inhouse Funds"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <hr>
                </b-col>
                <b-col
                  cols="12"
                >
                  <h5 class="mb-0">
                    Criteria
                  </h5>
                </b-col>
                <b-col md="12">
                  <b-row
                    v-for="(item, i) in program.criteria"
                    :key="i"
                    class="mt-2"
                  >
                    <b-col>
                      <b-form-group
                        :label="`Criteria ${i + 1}`"
                        :label-for="`Criteria-${i + 1}`"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          :name="`Criteria ${i + 1}`"
                        >
                          <b-form-input
                            :id="`Criteria-${i + 1}`"
                            v-model="item.criteria"
                            :state="errors.length > 0 ? false:null"

                            placeholder="Criteria Title"
                            type="text"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col sm="auto">
                      <b-button
                        v-if="program.criteria.length > 1"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        :class="{'mr-2': i === program.criteria.length-1}"
                        class="mt-2"
                        variant="outline-danger"
                        @click="program.criteria.splice(i-1, 1)"
                      >
                        <feather-icon
                          class="mr-25"
                          icon="XIcon"
                        />
                      </b-button>
                      <b-button
                        v-if="i === program.criteria.length-1"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="mt-2"
                        variant="outline-primary"
                        @click="program.criteria.push({criteria: null, description: null})"
                      >
                        <feather-icon
                          class="mr-25"
                          icon="PlusIcon"
                        />
                        <span>Add Criteria</span>
                      </b-button>

                    </b-col>
                    <b-col cols="12">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="`Criteria ${i + 1} Description`"
                      >
                        <b-form-input
                          :id="`description-${i + 1}`"
                          v-model="item.description"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Criteria Description"
                          type="text"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>

                  </b-row>
                </b-col>
                <b-col>
                  <hr>
                </b-col>
                <b-col
                  cols="12"
                >
                  <h5 class="mb-0">
                    Deliverables
                  </h5>
                </b-col>
                <b-col md="12">
                  <b-row
                    v-for="(item, i) in program.deliverables"
                    :key="i"
                    class="mt-2"
                  >
                    <b-col>
                      <b-form-group
                        :label="`Deliverable ${i + 1}`"
                        :label-for="`Deliverable-${i + 1}`"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          :name="`Deliverable ${i + 1}`"
                        >
                          <b-form-input
                            :id="`Deliverable-${i + 1}`"
                            v-model="item.deliverable"
                            :state="errors.length > 0 ? false:null"

                            placeholder="Deliverable Title"
                            type="text"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col sm="auto">
                      <b-button
                        v-if="program.deliverables.length > 1"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        :class="{'mr-2': i === program.deliverables.length-1}"
                        class="mt-2"
                        variant="outline-danger"
                        @click="program.deliverables.splice(i-1, 1)"
                      >
                        <feather-icon
                          class="mr-25"
                          icon="XIcon"
                        />
                      </b-button>
                      <b-button
                        v-if="i === program.deliverables.length-1"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="mt-2"
                        variant="outline-primary"
                        @click="program.deliverables.push({deliverable: null, description: null})"
                      >
                        <feather-icon
                          class="mr-25"
                          icon="PlusIcon"
                        />
                        <span>Add Deliverable</span>
                      </b-button>

                    </b-col>
                    <b-col cols="12">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="`Deliverable ${i + 1} Description`"
                      >
                        <b-form-input
                          :id="`description-${i + 1}`"
                          v-model="item.description"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Deliverable Description"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-col>

              </b-row>
            </validation-observer>
          </tab-content>

        </form-wizard>

      </div>
    </b-col>
    <b-modal
      id="eventModal"
      :ok-title="`${(program.lifeCycleStage && program.industry && program.customerFocus && program.offeringsType && program.impactFocus && program.lifeCycleStage.length === 0 && program.industry.length === 0 && program.customerFocus.length === 0 && program.offeringsType.length === 0 && program.impactFocus.length === 0)?'Skip':'Save'}`"
      no-close-on-backdrop
      size="lg"
      title="Additional Details"
      @ok="handleNewSave"
    >
      <h4>You can select multiple options.</h4>
      <b-row>
        <b-col md="12">
          <h6><strong>Focus</strong></h6>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Customer Focus"
            label-for="customerFocus"
          >
            <v-select
              id="customerFocus"
              v-model="program.customerFocus"
              :options="['B2C', 'D2C', 'B2B', 'B2G', 'B2B2C', 'B2B2B', 'B2C2B', 'B2C2C', 'B2B2G', 'B2G2B', 'B2G2C', 'B2C2G']"
              multiple
              placeholder="Select one or multiple options"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Offerings Type"
            label-for="offeringsType"
          >
            <v-select
              id="offeringsType"
              v-model="program.offeringsType"
              :options="['Product', 'Service', 'Hybrid']"
              multiple
              placeholder="Select one or multiple options"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Impact Focus"
            label-for="impactFocus"
          >
            <v-select
              id="impactFocus"
              v-model="program.impactFocus"
              :options="['Profit', 'Social', 'Environmental']"
              multiple
              placeholder="Select one or multiple options"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
      <!--      <form-builder-->
      <!--        v-model="applicationForm"-->
      <!--      />-->
    </b-modal>
    <b-modal
      id="submitModal"
      ok-title="Yes, Submit"
      ok-variant="success"
      button-size="sm"
      no-close-on-backdrop
      centered
      size="sm"
      title="Confirm Submit"
      @ok="addProgram()"
      @cancel="$bvModal.show('eventModal')"
    >
      <h6>Adding additional details helps you get the right applicants and participants. Are you sure you do not want to add details?</h6>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BButton,
  BCol,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  BFormFile,
  BFormInput,
  BRow,
} from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { email, required } from '@core/utils/validations/validations'
import gql from 'graphql-tag'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import DurationInput from '@/views/incubator-portal/programs/components/DurationInput.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapGetters } from 'vuex'
import industryList from '@/constants/industry-list'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BFormFile,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BButton,
    BFormCheckbox,
    BFormCheckboxGroup,
    DurationInput,
  },
  data() {
    return {
      program: {
        programId: null,
        title: null,
        type: null,
        file: null,
        image: null,
        duration: null,
        description: null,
        capacity: null,
        lifeCycleStage: [],
        industry: null,
        fundraisingDisable: false,
        fundraising: [],
        fundRaisingSize: ['', ''],
        inHouseFunds: null,
        inHouseFundsSize: null,
        criteria: [
          {
            criteria: null,
            description: null,
          },
        ],
        deliverables: [
          {
            deliverable: null,
            description: null,
          },
        ],
      },
      required,
      email,
      industries: industryList,
    }
  },
  computed: {
    ...mapGetters({
      selectedOrg: 'user/getSelectedOrgDetails',
    }),
  },
  methods: {

    handleNewSave() {
      if (this.program.lifeCycleStage && this.program.industry && this.program.customerFocus && this.program.offeringsType && this.program.impactFocus && this.program.lifeCycleStage.length === 0 && this.program.industry.length === 0 && this.program.customerFocus.length === 0 && this.program.offeringsType.length === 0 && this.event.impactFocus.length === 0) {
        this.$bvModal.show('submitModal')
      } else {
        this.addProgram()
      }
    },

    // eslint-disable-next-line consistent-return
    async handleFileUpload() {
      try {
        return await this.uploadFile(this.file)
      } catch (error) {
        this.showAlert('Error uploading file!', 'Error')
      } finally {
        this.mutationLoading = false
      }
    },
    formSubmitted() {
      if (!this.$route.params.id) {
        this.addProgram()
      }
    },
    addProgram() {
      if (this.program.file) {
        this.handleFileUpload().then(data => {
          this.program.image = data
          delete this.program.file
        })
        return false
      }
      this.$apollo.mutate({
        // Query
        mutation: gql`mutation (
            $criteriaInput: [programs_shortlistingcriteriontable_insert_input!]!,
            $deliverableInput: [programs_deliverablelisttable_insert_input!]!
            ) {
            insert_programs_basicinfo_one(object: {
            status: "draft",
            is_program: true,
            organization_id_id: "${this.selectedOrg.orgId}",
            title: "${this.program.title}",
            image:"${this.program.image}",
            type: "${this.program.type}",
            description: "${this.program.description}",
            duration: "${this.program.duration}",
            ideal_lifecycle_stage: "${this.program.lifeCycleStage.join(', ')}",
            capacity: "${this.program.capacity}",
            industry: "${this.program.industry}",
            fund_raising_assistance: "${this.program.fundraising.join(', ')}",
            inhouse_funds: ${this.program.inHouseFundsSize},
            ticket_size_of_fund_raised: "${this.program.fundRaisingSize[1]}",
            inhouse_ticket_size: "${this.program.fundRaisingSize[0]}",
            customer_type: "${this.program.customerFocus.join(', ')}",
            offerings_type: "${this.program.offeringsType.join(', ')}",
            focus_type: "${this.program.impactFocus.join(', ')}"
            programs_shortlistingcriteriontables: {data: $criteriaInput},
            programs_deliverablelisttables: {data: $deliverableInput}})
            {
                id
            }
        }`,
        variables: {
          criteriaInput: this.program.criteria.filter(criteria => criteria.criteria),
          deliverableInput: this.program.deliverables.filter(deliverable => deliverable.deliverable),
        },
        update: (store, { data: { insert_programs_basicinfo_one } }) => {
          if (insert_programs_basicinfo_one.id) {
            this.$router.push({ name: 'manage-programs' })
            this.showAlert('Program Added Successfully', 'Success')
          } else {
            this.mutationLoading = false
            this.showAlert('Error adding program', 'Error')
          }
        },
      })
      return this.error
    },
    validationBasicInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.basicInfo.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
